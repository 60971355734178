import Vue from 'vue';
import App from './App.vue';

import VueRouter from 'vue-router';
Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            component: () => App,
            meta: {
                keepAlive: true // 需要被缓存
            }
        },
        {
            path: '/index.html',
            component: () => App,
            meta: {
                keepAlive: true // 需要被缓存
            }
        },
        {
            path: '/home',
            component: () => import('./pages/home'),
            meta: {
                keepAlive: false // 不需要被缓存
            }
        },
        {
            path: '/terms',
            component: () => import('./pages/terms')
        },
        {
            path: '/purchase',
            component: () => import('./pages/purchase'),
            meta: {
                keepAlive: false // 不需要被缓存
            }
        },
        {
            path: '/purchase-history',
            component: () => import('./pages/purchase-history'),
            meta: {
                keepAlive: false // 不需要被缓存
            }
        },
        {
            path: '/transfer',
            component: () => import('./pages/transfer'),
            meta: {
                keepAlive: false // 不需要被缓存
            }
        },
        {
            path: '/transfer-history',
            component: () => import('./pages/transfer-history'),
            meta: {
                keepAlive: false // 不需要被缓存
            }
        },
        {
            path: '/customer',
            component: () => import('./pages/customer'),
            meta: {
                keepAlive: false // 不需要被缓存
            }
        },
        {
            path: '/withdraw',
            component: () => import('./pages/withdraw'),
            meta: {
                keepAlive: false // 不需要被缓存
            }
        },
        {
            path: '*',
            redirect: '/'
        }
    ]
});

import VueClipboard from 'vue-clipboard2';

Vue.use(VueClipboard);

import i18n from '@/lang';

import logger from '@/utils/logger';

Vue.prototype.$log = logger;

Vue.config.productionTip = false;

let padding = function(d) {
    return d >= 10 ? `${d}` : `0${d}`;
}
let monthes = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', ];

Vue.filter('formatDate', d => {
    return `${monthes[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
});
Vue.filter('formatTime', d => {
    return `${padding(d.getHours())}:${padding(d.getMinutes())}:${padding(d.getSeconds())}`;
});

Vue.filter('formatNum', d => {
    return (d || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
});

Vue.filter('formatPrice', d => {
    d = (d || 0).toFixed(2);
    let nums = d.split('.', 2);
    // return nums[0].replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') + '.' + nums[1];
    return nums[0].replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
});

import message from 'ant-design-vue/lib/message';
message.config({
    duration: 3,
    maxCount: 1
});

import { Radio } from 'ant-design-vue';
// import 'ant-design-vue/lib/radio/style/index.css';
import '@/assets/css/antv-radio.css';
Vue.use(Radio);

import { Carousel, CarouselItem,  } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.component(Carousel.name, Carousel);
Vue.use(CarouselItem);

import { Tabs, TabPane,  } from 'element-ui';
Vue.component(Tabs.name, Tabs);
Vue.use(TabPane);

import VueLazyload from 'vue-lazyload';

Vue.use(VueLazyload, {
    attempt: 1
});

import request from '@/utils/request';

Vue.prototype.$http = request;

new Vue({
    router,
    i18n,
    render: h => h(App)
}).$mount('#app');
