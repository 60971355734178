/* eslint-disable prettier/prettier */
export default {
    '{0} invited {1} friends, get {2} Rs. reward': '{0} invited {1} friends, get {2} Rs. reward',
    '{0} invited {1} friends, got {2} Rs. commission': '{0} invited {1} friends, got {2} Rs. commission',
    'START NOW': 'START NOW',
    'Already registered, log in now': 'Already registered, log in now',
    'RULE DESCRIPTION': 'RULE DESCRIPTION',
    'HOW TO MAKE MONEY?': 'HOW TO MAKE MONEY?',
    'The dealer can promote the game, and the promotion of the game revenue generated by the user, the dealer can get a commission, the more users, the greater the revenue.': 'The dealer can promote the game, and the promotion of the game revenue generated by the user, the dealer can get a commission, the more users, the greater the revenue.',
    'How to promote the game?': 'HOW TO SHARE THIS GAME?',
    'Apply to become a promoter': 'Apply to become a promoter',
    'Send an invitation link to your friends or target audience.': 'Send an invitation link to your friends or target audience.',
    'Click the promotion link to download and install the game': 'Click the promotion link to download and install the game',
    'Referral Link': 'Referral Link',
    'Copy': 'Copy',
    'Share your link': 'Share your link',
    'More': 'More',
    'REFERRAL BONUS': 'REFERRAL BONUS',
    'Rs': 'Rs',
    'MY': 'MY',
    'AGENT': 'AGENT',
    'Total commission': 'Total commission',
    'Withdraw': 'Withdraw',
    'Time': 'Time',
    'DATE': 'DATE',
    'NEW INSTALLATION': 'NEW INSTALLATION',
    'ACTIVE DAY': 'ACTIVE DAY',
    'INCOME': 'INCOME',
    '(Must be installed through the promotion link, cannot be transmitted through sharelt)': '(Must be installed through the promotion link, cannot be transmitted through sharelt)',
    'Only valid for Android users, iOS is temporarily not supported': 'Only valid for Android users, iOS is temporarily not supported',
    'Withdrawal instructions': 'Withdrawal instructions',
    'If you would like to withdraw the money you have earned, the minimum withdrawal amount under this Referral Program should be reached RS.1000. Please note that the income amount will be cleared after withdrawing.': 'If you would like to withdraw the money you have earned, the minimum withdrawal amount under this Referral Program should be reached RS.1000. Please note that the income amount will be cleared after withdrawing.',
    'There are several first payment methods that need to be provid when withdrawing cash.': 'There are several first payment methods that need to be provid when withdrawing cash.',
    'The funds efficiency varies from payment methods, which usually takes 3-7 business days.': 'The funds efficiency varies from payment methods, which usually takes 3-7 business days.',
    'EMAIL': 'EMAIL',
    'PHONE NUMBER': 'PHONE NUMBER',
    'CAPTCHA': 'CAPTCHA',
    'LOG IN': 'LOG IN',
    'SIGN UP': 'SIGN UP',
    'Forgot password ?': 'Forgot password ?',
    'PASSWORD': 'PASSWORD',
    'CONFIRM PASSWORD': 'CONFIRM PASSWORD',
    'Log in with already account': 'Log in with already account',
    'Forget Password': 'Forget Password',
    'VERIFICATION': 'VERIFICATION',
    'Email retrieval': 'Email retrieval',
    'Phone number retrieval': 'Phone number retrieval',
    'NEW PASSWORD': 'NEW PASSWORD',
    'CONFIRM NEW PASSWORD': 'CONFIRM NEW PASSWORD',
    'Log in with message': 'Log in with message',
    'Send the code': 'Send the code',
    'invite friends': 'invite friends',
    'The last {0} days': 'The last {0} days',
    'Last {0} days': 'Last {0} days',
    'Cancel': 'Cancel',
    'Confirm': 'Confirm',
    'Verify code was sent to your phone number': 'Verify code was sent to your phone number',
    'Failed to send verify code sms, please check your phone number and retry.': 'Failed to send verify code sms, please check your phone number and retry.',
    'Your verify code mismatch with phone numer': 'Your verify code mismatch with phone numer',
    'This phone numer has been registered!': 'This phone numer has been registered!',
    'Something went wrong with server, please retry later.': 'Something went wrong with server, please retry later.',
    'Your phone number or password is incorrect, check and retry.': 'Your phone number or password is incorrect, check and retry.',
    'Your password has been changed.': 'Your password has been changed.',
    'Your phone number is incorrect, check and retry.': 'Your phone number is incorrect, check and retry.',
    'Login info expires, please login.': 'Login info expires, please login.',
    'Balance is not enough for withdraw. Earning more and try again.': 'Balance is not enough for withdraw. Earning more and try again.',
    'Try too many withdraws in 24 hours. Earning more and try again.': 'Try too many withdraws in 24 hours. Earning more and try again.',
    'Withdraw service failed temporarily. Earning more and try again.': 'Withdraw service failed temporarily. Earning more and try again.',
    'Your promotional link copied.': 'Referral link copied.',
    'Your referral link copied.': 'Referral link copied.',
    'Share failed or canceled': 'Share failed or canceled',
    'Something went wrong with sharing.': 'Something went wrong with sharing.',
    'Withdraw success and go to cash check.': 'Withdraw success and go to cash check.',
    'Install': 'Install',
    'Recharge in-game': 'Recharge in-game',
    'Double': 'Double',
    'WhatsApp': 'WhatsApp',
    'Facebook': 'Facebook',
    'Messenger': 'Messenger',
    'How to earn more money?': 'How to earn more money?',
    'Copy the unique URL link on your personal page to others, and all users registered by this link will be your agents.': 'Copy the unique URL link on your personal page to others, and all users registered by this link will be your agents.',
    'Type your phone number': 'Type your phone number',
    'At least {0} can be withdrawn': 'At least {0} can be withdrawn',
    'Withdraw in progress, check and handle it first.': 'Withdraw in progress, check and handle it first.',
    'At most {0} withdraw in 24 hours': 'At most {0} withdraw in 24 hours',
    'Invalid mobile phone number': 'Invalid phone number',
    'Phone number required': 'Phone number required',
    'OTP required': 'OTP required',
    'Download': 'Download',
    'Processing': 'Processing',
    'Failed': 'Failed',
    'Succeeded': 'Succeeded',
    'success': 'Succeeded',
    'fail': 'Failed',
    'pending': 'Processing',
    '₹': '₹',
    'Welcome to Taurus, and receive ₹ {0}!': 'Welcome to Taurus, and receive ₹ {0}!',
    'Refer & Earn': 'Refer & Earn',
    'Current Balance': 'Current Balance',
    'Discounts Store': 'Discounts Store',
    'Rules': 'Rules',
    'My Commission': 'My Commission',
    'Date': 'Date',
    'The withdrawal has been successful and money will be sent to you within 7 working days.': 'The withdrawal has been successful and money will be sent to you within 7 working days.',
    'You\'ve reached the maximum number of attempts to get OTP today. Please try again 24 hours later.': 'You\'ve reached the maximum number of attempts to get OTP today. Please try again 24 hours later.',
    'Sharing successful': 'Sharing successful',
    'The system is upgrading, please try later.': 'The system is upgrading, please try later.',
    'Tips: Send to a specific user': 'Tips: Send to a specific user',
    '* Invalid ID': '* Invalid ID',
    'Payee (Mobile Number)': 'Payee (Mobile Number)',
    'Type User ID': 'Type User ID',
    'Type Amount': 'Type Amount',
    "Can't recharge for yourself": "Can't recharge for yourself",
    'NAME': 'NAME',
    'Type your name': 'Type your name',
};
