
import axios from 'axios';
import Cookie from '@/utils/cookie';
import { production } from '../config';

// create an axios instance   创建axios实例
const http = axios.create({
    // baseURL: production ? 'http://13.233.238.138:5682' : 'http://192.168.1.106:8201', // api 的 base_url
    // baseURL: production ? 'https://api.tcvg.pw' : 'http://192.168.1.106:8201', // api 的 base_url
    baseURL: production ? 'https://aw.taurus.pub' : 'http://192.168.1.106:18201', // api 的 base_url
    timeout: 30000, // request timeout  设置请求超时时间
    responseType: "json",
    withCredentials: true, // 是否允许带cookie这些
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      // "Host": "api.tcvg.pw",
      // "Origin": "api.tcvg.pw",
    }
  });

http.interceptors.request.use(config => {
    let token = Cookie.getCookie('token');
    if (token) {
        config.headers['token'] = token;
    }
    if (!production) {
      config.url = config.url.replace('/awclient', '');
    }
    return config;
});

export default http;
